import { Header, Footer } from '../../layouts/index';

const ErrorPage = () => {
  // 認可エラー
  const AuthorizationError = () => {
    return (
      <div className="fs-xlarge">
        <ul>
          <li>
            <p>
              アプリ利用権限がないためログインできません。洪水リスクファインダーのWebページより、
              <br />
              利用申込を行ってください。 （
              <a href={`${process.env.REACT_APP_LANDING_PAGE}`} target="_blank" rel="noopener noreferrer">
                こちら
              </a>
              ）
              <br />
              ご不明なことがある場合、Webページ下部の“Contact (日本語)”からご連絡ください。
              <br />
            </p>
          </li>
          <li>
            <p>
              You cannot log in because you do not have the necessary app permissions.
              <br />
              Please apply for use through the Flood Risk Finder web page. （
              <a href={`${process.env.REACT_APP_LANDING_PAGE_EN}`} target="_blank" rel="noopener noreferrer">
                here
              </a>
              ）
              <br />
              If you have any questions, please contact us through the "Contact (English)" at the bottom of this page.
            </p>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <div>
      <Header isErrorPage={true} />
      <main className="main">
        <div className="container">
          {/* 認可エラー */}
          <AuthorizationError />
          {/* 今後、他のエラーの実装が必要になった場合はpropsにtypeなどを渡して分岐する */}
        </div>
      </main>
      <Footer isErrorPage={true} />
    </div>
  );
};

export default ErrorPage;
