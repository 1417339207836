import { useLocation } from 'react-router-dom';
import { FooterCopyright, FooterDocument } from './components/index';

const Footer = (props) => {
  const pathName = useLocation().pathname;
  return (
    <footer className="footer">
      <div className={pathName === '/' ? 'container-fluid' : 'container'}>
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <FooterDocument isErrorPage={props.isErrorPage} />
          <FooterCopyright />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
