import { useLocation } from 'react-router-dom';
import { HeaderTitle, HeaderLogo, HeaderButton } from './components/index';

const Header = (props) => {
  const pathName = useLocation().pathname;
  const userInfo = props.userInfo ? props.userInfo : null;

  return (
    <header className="header">
      <div className={pathName === '/' ? 'container-fluid' : 'container'}>
        <div className="header-inner">
          <HeaderLogo />
          {!props.isErrorPage && (
            <>
              <HeaderTitle />
              <HeaderButton userInfo={userInfo} />
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
