const FooterDocument = (props) => {
  return (
    <div className="footer-link-group">
      {!props.isErrorPage && (
        <span>
          <a href={`${process.env.REACT_APP_FOOTER_MANUAL}`} target="_blank" rel="noopener noreferrer">
            Manual
          </a>
        </span>
      )}
      <span>
        <a href={`${process.env.REACT_APP_FOOTER_CONTACT}`} target="_blank" rel="noopener noreferrer">
          Contact（日本語）
        </a>
      </span>
      <span>
        <a href={`${process.env.REACT_APP_FOOTER_CONTACT_EN}`} target="_blank" rel="noopener noreferrer">
          Contact（English）
        </a>
      </span>
    </div>
  );
};

export default FooterDocument;
